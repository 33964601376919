import { css } from '@emotion/core'

export const reset = css`
  /* Box sizing rules */
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  body {
    font-family: 'Work Sans', sans-serif;
    color: #2f2f2f;

    @media (prefers-color-scheme: dark) {
      color: #dcdcdc;
      background-color: #0f0f0f;
    }
  }

  /* Remove default padding */
  ul,
  ol {
    padding: 0;
  }

  /* Remove default margin */
  body,
  h1,
  h2,
  h3,
  h4,
  p,
  ul,
  ol,
  li,
  figure,
  figcaption,
  blockquote,
  dl,
  dd {
    margin: 0;
  }

  /* Set core body defaults */
  body {
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
    overflow-x: hidden;
  }

  /* Remove list styles on ul, ol elements with a class attribute */
  ul[class],
  ol[class] {
    list-style: none;
  }

  /* A elements that don't have a class get default styles */
  a {
    text-decoration: none;

    &:not([class]) {
      text-decoration-skip-ink: auto;
    }
  }

  a {
    background-color: #2063cc;
    background-image: linear-gradient(90deg, #2063cc, #3884ff);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
    font-weight: 700;
    position: relative;

    @media(min-width: 769px) {
      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 0px;
        background-image: linear-gradient(90deg, #2063cc, #3884ff);
        position: absolute;
        top: 100%;
        left: 0;
        transition: height 0.1s ease-out;
      }
  
      &:hover {
        &::after {
          height: 3px;
        }
      }
    }
  }

  /* Make images easier to work with */
  img {
    max-width: 100%;
    display: block;
  }

  /* Inherit fonts for inputs and buttons */
  input,
  button,
  textarea,
  select {
    font: inherit;
  }

  /* Remove all animations and transitions for people that prefer not to see them */
  @media (prefers-reduced-motion: reduce) {
    * {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }
`

export const container = css`
  min-width: 100%;
  min-height: 100vh;
  padding: 24px;
  background-color: #efefef;
  background-image: radial-gradient(circle at 1px 1px, #cccccc 1px, transparent 0);
  background-size: 20px 20px;
  background-attachment: fixed;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 4fr;
  grid-template-areas: "sticky content";
  grid-gap: 24px;

  @media(max-width: 1280px) {
    grid-template-columns: 1fr 3fr;
  }

  @media(max-width: 768px) {
    grid-template-columns: 1fr 2fr;
  }

  @media(max-width: 480px) {
    display: block;
    padding-top: 0;
  }

  @media (prefers-color-scheme: dark) {
    background-color: #0f0f0f;
    background-image: radial-gradient(circle at 1px 1px, #1e1e1e 1px, transparent 0);
  }
`

export const leftSide = css`
  width: calc((100% - 120px) / 5);
  height: calc(100vh - 48px);
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  position: fixed;
  top: 24px;
  left: 24px;
  bottom: 24px;
  grid-area: sticky;
  z-index: 999;

  @media(max-width: 1280px) {
    width: calc((100% - 96px) / 4);
  }

  @media(max-width: 768px) {
    width: calc((100% - 72px) / 3);
  }

  @media(max-width: 480px) {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    width: calc(100% + 48px);
    height: auto;
    padding: 24px;
    margin: 0 -24px;
    background: linear-gradient(rgba(239, 239, 239, 1) 50%, rgba(239, 239, 239, 0));
    font-size: 48px;
    position: sticky;
    top: 0;
    bottom: unset;
    right: 0;
    left: 0;

    @media(prefers-color-scheme: dark) {
      background: linear-gradient(rgba(15, 15, 15, 1) 50%, rgba(15, 15, 15, 0));
    }
  }
`

export const rightSide = css`
  grid-area: content;
`

export const name = css`
  margin: 0;
  font-family: 'Rubik';
  font-size: 88px;
  font-weight: 900;
  line-height: 0.9;
  letter-spacing: -1px;
  text-transform: uppercase;
  flex-grow: 1;

  span {
    display: inline-block;
  }

  @media(max-width: 768px) {
    font-size: 72px;
  }

  @media(max-width: 480px) {
    font-size: 48px;
  }
`

export const copyright = css`
  margin-top: 16px;
  opacity: 0.3;
  font-family: 'Rubik';
  font-size: 16px;
  text-transform: uppercase;

  @media(max-width: 480px) {
    display: none;
  }
`
