import { css } from '@emotion/core'

export const sectionTitle = css`
  margin: 0 0 24px;
  color: #2f2f2f;
  -webkit-text-stroke: 1px #2f2f2f;
  -webkit-text-fill-color: transparent;
  font-family: 'Rubik', sans-serif;
  font-size: 72px;
  line-height: 1;
  letter-spacing: -1px;
  text-transform: uppercase;

  @media(max-width: 768px) {
    font-size: 56px;
  }

  @media(max-width: 480px) {
    font-size: 40px;
    margin-bottom: 16px;
  }
`


export const contactContainer = css`
  display: grid;
  width: 100%;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 24px;

  @media(max-width: 1280px) {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media(max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media(max-width: 480px) {
    display: block;
  }
`

export const email = css`
  grid-row: 1 / 2;
  grid-column: 1 / 4;

  h3 {
    margin: 0;
    font-family: 'Rubik';
    font-size: 56px;
    line-height: 1;
    letter-spacing: -1px;
    text-transform: uppercase;
  }

  a {
    line-height: 1;
    text-transform: uppercase;
  }

  @media(max-width: 768px) {
    grid-column: 1 / 3;

    h3 {
      font-size: 40px;
    }
  }

  @media(max-width: 480px) {
    h3 {
      display: none;
    }
  }
`

export const socialLinks = css`
  grid-row: 1 / 2;
  grid-column: 4 / 5;

  li {
    list-style-type: none;

    a {
      line-height: 1;
      text-transform: uppercase;
    }
  }

  @media(max-width: 1280px) {
    grid-row: 2 / 3;
    grid-column: 1 / 4;
  }

  @media(max-width: 768px) {
    grid-column: 1 / 3;
  }
`
