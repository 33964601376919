import { css } from '@emotion/core';

export const sectionTitle = css`
  margin: 0 0 24px;
  color: #2f2f2f;
  -webkit-text-stroke: 1px #2f2f2f;
  -webkit-text-fill-color: transparent;
  font-family: 'Rubik', sans-serif;
  font-size: 72px;
  line-height: 1;
  letter-spacing: -1px;
  text-transform: uppercase;

  @media(max-width: 768px) {
    font-size: 56px;
  }

  @media(max-width: 480px) {
    font-size: 40px;
    margin-bottom: 8px;
  }

  @media (prefers-color-scheme: dark) {
    color: #dcdcdc;
    -webkit-text-stroke: 1px #dcdcdc;
  }
`