import { css } from '@emotion/core';

export const logo = css`
  display: inline-block;
  width: 0;
  height: 0;
  border: 1.8em solid #2f2f2f;
  border-right-color: transparent;
  
  @media(max-width: 480px) {
    border-width: 0.4em;
  }

  @media(prefers-color-scheme: dark) {
    border-color: #dcdcdc;
    border-right-color: transparent;
  }
`