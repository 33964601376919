import React from "react"
import PropTypes from "prop-types"
import { Global, css } from "@emotion/core"

import Footer from "../footer"
import Logo from "../logo"

import * as styles from "./styles"

const Layout = ({ professional, children }) => {
  const year = new Date().getFullYear()

  return (
    <div>
      <Global styles={css`${styles.reset}`}/>
      <main css={styles.container}>
        <div css={styles.leftSide}>
          <h1 css={styles.name}>Kevin Ng</h1>
          <Logo />
          <p css={styles.copyright}>(C) Kevin Ng {year}</p>
        </div>
        <div css={styles.rightSide}>
          {children}
          <Footer professional={professional} />
        </div>
      </main>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
