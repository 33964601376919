import React from "react"

import SectionTitle from "../sectionTitle"

import * as styles from "./styles"

const Footer = ({professional}) => (
    <>
        <SectionTitle>Contact</SectionTitle>
        <div css={styles.contactContainer}>
            <div css={styles.email}>
                <a href="mailto:kevin@kevinng.ca">Email</a>
                <h3>Kevin(at)Kevinng.ca</h3>
            </div>
            {professional ? (<ul css={styles.socialLinks}>
                <li><a href="https://github.com/ngkay" target="blank">Github</a></li>
                <li><a href="https://www.linkedin.com/in/kvnng/" target="blank">LinkedIn</a></li>
            </ul>) : (<ul css={styles.socialLinks}>
                <li><a href="https://www.instagram.com/kevin.kw.ng/" target="blank">Instagram</a></li>
                <li><a href="https://www.tiktok.com/@kevin.kw.ng" target="blank">TikTok</a></li>
                <li><a href="https://www.youtube.com/channel/UCsuisJMuIgQlxLCzYQRPo7w" target="blank">YouTube</a></li>
                <li><a href="https://twitter.com/NGKayyy" target="blank">Twitter</a></li>
            </ul>)}
        </div>
    </>
)

export default Footer
